<template>
  <v-layout column wrap>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">COOP INCOME SUMMARY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-flex xs12 class="mx-2">
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4>
            <v-select
              v-model="category"
              class="mx-3 pt-6"
              :items="['Yearly','Monthly']"
              label="Category"
              required
              dense
              @change="get_list_of_coop_income_summary_items"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 class="mx-2">
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4>
            <v-select
              v-if="category==='Yearly'"
              v-model="year_of"
              class="mx-3 pt-5"
              :items="year_of_items"
              item-value="year"
              item-text="year"
              label="Year Of"
              required
              dense
              @change="get_list_of_coop_income_summary_items"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 class="mx-2">
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4>
            <v-select
              v-if="category==='Monthly'"
              v-model="month_of"
              class="mx-3 pt-5"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              label="Month Of"
              required
              dense
              @change="get_list_of_coop_income_summary_items"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-data-table
        dense
        class="pt-6"
        :headers="headers"
        :items="list_of_loan_savings"
        :search="search"
        :loading="data_table_loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <span class="headline">TOTAL: {{total}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span
              v-if="list_of_loan_savings.length>0">
                                                Print Data
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
            <v-spacer></v-spacer>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearchOutline"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
  import {mapGetters} from 'vuex'
  import {mapActions} from 'vuex'
  import {mdiAccountSearchOutline, mdiPrinter} from '@mdi/js'
  import moment from "moment";

  export default {
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        selected_month_of: 'NA',
        category: '',
        month_of_items: [],
        month_of: '',
        year_of_items: [],
        year_of: '',
        list_of_loan_savings: [],
        total: 0,
        //table props
        search: '',
        data_table_loading: false,
        headers: [
          {text: 'Category', value: 'category'},
          {text: 'Total', value: 'amount'},
        ],
      }
    },
    mounted() {
      const data = new FormData()
      data.append('branch_id', this.branch_id);
      this.retrieve_transaction_month(data)
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
      this.retrieve_all_transaction_year({
        db_url: this.branch_db_url,
      })
        .then((response) => {
          this.year_of_items = response.data
          this.year_of_items.splice(0, 0, {
            id: this.year_of_items[0].year + 1,
            year: 'All'
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id','branch', 'branch_address', 'branch_contact_no']),
    },
    watch: {
      branch_id: function () {
        this.can_print = false
        this.month_of = ''
        this.list_of_loan_savings = []
        this.total = '0'
        this.selected_month_of = 'NA'
      }
    },
    methods: {
      ...mapActions('transaction_months', ['retrieve_transaction_month', 'retrieve_all_transaction_year']),
      ...mapActions('income_summary', ['get_summary_income']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      get_list_of_coop_income_summary_items() {
        this.list_of_loan_savings = [];
        if (this.category === 'Yearly') {
          if (this.year_of_items.length > 0) {
            var indexs = this.year_of_items.map(function (x) {
              return x.year
            }).indexOf(this.year_of)
            if (indexs != -1) {
              this.selected_month_of = this.year_of_items[indexs].year;
            }
          }
        } else {
          if (this.month_of_items.length > 0) {
            var index = this.month_of_items.map(function (x) {
              return x.id
            }).indexOf(this.month_of)
            if (index != -1) {
              this.selected_month_of = this.month_of_items[index].month_of;

            }
          }
        }
        if (this.selected_month_of != 'NAN') {
          this.data_table_loading = true
          const data = new FormData()
          data.append('month_of', this.selected_month_of);
          data.append('branch_id', this.branch_id);
          this.get_summary_income(data)
            .then((response) => {
              this.total = response.data[0].total_income
              this.list_of_loan_savings = response.data[0].data
              this.data_table_loading = false
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      print() {
        var imgData = this.company_logo
        var payments_array = []

        var month = this.selected_month_of
        var total = this.total
        payments_array.push(
          [
            {text: 'Category', alignment: 'center', style: 'label'},
            {text: 'Total', alignment: 'center', style: 'label'},
          ]
        )
        if (this.list_of_loan_savings.length > 0) {
          this.list_of_loan_savings.forEach(function (item) {
            payments_array.push(
              [
                {text: item.category, alignment: 'center'},
                {text: item.amount, alignment: 'center'},
              ]
            )
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'INCOME SUMMARY', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Month of: ' + month, style: 'main_info'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Total: ' + total, style: 'main_info', color: 'red'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [120, 60, 60, 60, 60, 90],
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Collector', style: 'aa_placeholder'},
                    {text: 'Cashier', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'cs_top_margin'},
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'cs_for'},
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'cs_line'},
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Collection Supervisor', style: 'cs_placeholder'},
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
